import Vue from 'vue'
import Router from 'vue-router'
const Register = () => import('@/views/register.vue')
const Home = () => import('@/views/home.vue')
const Login = () => import('@/views/login.vue')
const Cabinet = () => import('@/views/cabinet/index.vue')
// const MyInfo = () => import('@/views/cabinet/myinfo.vue')
const MyInfo1 = () => import('@/views/cabinet/myinfo1.vue')
const Oferta = () => import('@/views/oferta.vue')
const News = () => import('@/views/news/index.vue')
const Inspector = () => import('@/views/inspector/index.vue')
const ControlFunction = () => import('@/views/controlfunction/index.vue')
const Complaint = () => import('@/views/complaint/index.vue')
const ComplaintView = () => import('@/views/complaint/view.vue')
const EditComplaint = () => import('@/views/complaint/edit.vue')
const MandatoryRequirement = () => import('@/views/mandatoryrequirement/index.vue')
const Request = () => import('@/views/cabinet/request/index.vue')
const RequestView = () => import('@/views/cabinet/request/view.vue')
const InspectionBookOfContractor = () => import('@/views/inspectionbookofcontractor/index.vue')
const InspectionBookOfContractorEdit = () => import('@/views/inspectionbookofcontractor/edit.vue')
const InspectionBookOfContractorView = () =>
  import("@/views/inspectionbookofcontractor/view.vue");
const InspectionBook = () => import("@/views/inspectionbook/index.vue");
const InspectionBookEdit = () => import("@/views/inspectionbook/edit.vue");
const Oked = () => import("@/views/oked/index.vue");
const VideoLesson = () => import("@/views/videolesson/index.vue");
const Support = () => import("@/views/support.vue");
Vue.use(Router);

const router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  base: process.env.BASE_URL,
  scrollBehavior: () => ({
    y: 0,
    x: 0,
  }),
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        isAuth: false,
      },
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: {
        isAuth: false,
      },
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        isAuth: false,
      },
    },
    {
      path: "/cabinet",
      name: "Cabinet",
      component: Cabinet,
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/oferta",
      name: "Oferta",
      component: Oferta,
      meta: {
        isAuth: false,
      },
    },
    // {
    //     path : '/admquota'
    // },
    // {
    //     path: '/myinfo',
    //     name : 'info',
    //     component : MyInfo,
    //     meta : {
    //         isAuth : true
    //     }
    // },
    {
      path: "/myinfo",
      name: "info",
      component: MyInfo1,
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/inspector",
      name: "inspector",
      component: Inspector,
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/controlfunction",
      name: "controlfunction",
      component: ControlFunction,
      meta: {
        isAuth: false,
      },
    },
    {
      path: "/complaint",
      name: "complaint",
      component: Complaint,
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/complaint/edit/id=:id",
      name: "EditComplaint",
      component: EditComplaint,
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/complaint/view/id=:id",
      name: "ComplaintView",
      component: ComplaintView,
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/mandatoryrequirement",
      name: "mandatoryrequirement",
      component: MandatoryRequirement,
      meta: {
        isAuth: false,
      },
    },
    {
      path: "/inspectionbookofcontractor",
      name: "inspectionbookofcontractor",
      component: InspectionBookOfContractor,
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/inspectionbookofcontractor/edit/id=:id",
      name: "InspectionBookOfContractorEdit",
      component: InspectionBookOfContractorEdit,
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/inspectionbookofcontractor/view/id=:id",
      name: "InspectionBookOfContractorView",
      component: InspectionBookOfContractorView,
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/inspectionbook",
      name: "inspectionbook",
      component: InspectionBook,
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/inspectionbook/edit/id=:id",
      name: "InspectionBookEdit",
      component: InspectionBookEdit,
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/request",
      name: "Request",
      component: Request,
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/request/view/id=:id",
      name: "RequestView",
      component: RequestView,
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/oked",
      name: "oked",
      component: Oked,
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/videolesson",
      name: "videolesson",
      component: VideoLesson,
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/news",
      name: "News",
      component: News,
      meta: {
        isAuth: false,
      },
    },
    {
      path: "/support",
      name: "Support",
      component: Support,
      meta: {
        isAuth: false,
      },
    },
  ],
});
router.beforeEach((to, from, next) => {
    var auth = localStorage.getItem('user_info')
    if (to.meta.isAuth === true) {
        if (!!auth) {
            next()
        }
        else {
            next('/')
        }
    }
    else {
        next()
    }

})
export default router
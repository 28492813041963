import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
    state : {
        isOpenSidebar : false,
        isOpenSearch : false,
        admtype : localStorage.getItem('admtype') || 0,
        infobox : localStorage.getItem('infobox') == 1 && localStorage.getItem('infobox') ? false : true,
        menu_item : "main"
    }
})